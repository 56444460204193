<template>
  <div class="column no-padding is-full">
    <div class="card">
      <header class="card-header has-background-primary">
        <div @click.stop="$router.go(-1)" class="card-header-icon">
          <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
        </div>
        <p class="card-header-title has-text-light">KELOMPOK {{ kelompok.nama }}</p>
        <template v-if="isLoaded">
          <router-link
            :to="{ name: 'kelompok-change', params: {id: kelompok.id} }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="edit" />
          </router-link>
        </template>
      </header>
      <pspd-table v-if="isLoaded" :kelompok="kelompok"></pspd-table>
      <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
    </div>
  </div>
</template>


<script>
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import deleteMixin from "@/apps/core/mixins/deleteMixin";
import Kelompok from "../models/kelompok.js";

export default {
  name: "KelompokDetail",
  props: ["title"],
  mixins: [onlineCheckMixin, fetchCreatedMixin, deleteMixin],
  data() {
    this.kelompokMdl = new Kelompok();
    this.objectMdl = this.kelompokMdl;
    return this.kelompokMdl.getObservables();
  },
  components: {
    PspdTable: () => import("@/apps/anggota/components/PspdTable.vue"),
  },
  methods: {
    fetchData() {
      this.kelompokMdl.load(this.$route.params.id);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/detailview.scss";
.del-btn {
  color: #00d1b2 !important;
}
</style>