import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "kelompok";
const modelPath = "/anggota/tag/";

class Kelompok extends ModelBase {
  constructor() {
    let model = {
      id: null,
      nama: "",
      // pspd_set: []
    }
    super(modelName, model, [], [], modelPath);
  }
}

export default Kelompok;