import { render, staticRenderFns } from "./KelompokDetail.vue?vue&type=template&id=6794d57c&scoped=true&"
import script from "./KelompokDetail.vue?vue&type=script&lang=js&"
export * from "./KelompokDetail.vue?vue&type=script&lang=js&"
import style0 from "./KelompokDetail.vue?vue&type=style&index=0&id=6794d57c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.7_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6794d57c",
  null
  
)

export default component.exports